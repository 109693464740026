.react-date-picker,
.react-datetime-picker {
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 4px;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-date-picker__wrapper,
.react-datetime-picker__wrapper {
    border: 0;
}

.react-datetime-picker__inputGroup {
    white-space: nowrap;
}

.container-map {
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}

.mapScrollContainer {
    max-width: 100%;
    overflow-y: scroll;
}

.mapScrollContainer::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.mapScrollContainer::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.space-box {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.space-box p {
    width: 100%;
    height: auto;
    font-size: 12px;
    color: white;
    text-align: center;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
}

.space-box-vertical p {
    writing-mode: vertical-rl;
    text-orientation: sideways;
    width: auto;
    height: 100%;
}

.container-search-config {
    border-radius: 10px;
    box-shadow: 0px 0px 3px #555;
    width: 350px;
    position: fixed;
    top: 80px;
    left: 30px;
    background-color: white;
    padding: 20px;
    padding-top: 0px;
    transition: all .2s;
    opacity: 0.95;
    z-index: 100;
}
.container-search-config .content-minimized {
    display: none;
}

.container-search-config .btn-outline-dark:hover {
    background-color: #eee;
}

.container-search-config.minimized {
    width: 48px;
    height: 48px;
    padding: 0px;
    transition: all .2s;
}

.container-search-config.minimized .content {
    display: none;
}

.container-search-config .collapse-bar {
    text-align: right;
    margin-bottom: 20px;
    cursor: pointer;
}

.container-search-config.minimized .collapse-bar {
    text-align: center;
    padding-top: 11px;
    padding-bottom: 11px;
}

.container-search-config .collapse-bar .expand-icon {
    display: none;
}

.container-search-config .collapse-bar .expand-icon-bigscreen {
    display: none;
}

.container-search-config .collapse-bar .expand-icon-smallscreen {
    display: none;
}

.container-search-config.minimized .collapse-bar .expand-icon-bigscreen {
    display: inline;
}

.container-search-config .collapse-bar .collapse-icon-smallscreen {
    display: none;
}

.container-search-config.minimized .collapse-bar .collapse-icon {
    display: none;
}

.space-list {
    text-align: left;
}

.space {
    color: white;
}

.space-available {
    background-color: rgba(48, 209, 88, 0.9) !important;
}

.space-notavailable {
    background-color: rgba(255, 69, 58, 0.9) !important;
}

.space-mydesk {
    background-color: rgba(184, 37, 222, 0.9) !important;
}

.space-list-item-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.invalid-search-config {
    width: 100%;

    font-size: .875em;
    color: #dc3545;
}

@media only screen and (max-width: 600px), only screen and (max-height: 500px) {
    .space-list {
        padding-bottom: 250px;
    }
    .space-list.maximized {
        padding-bottom: 130px;
    }

    .container-map {
        margin-left: auto;
        margin-right: auto;
        padding-top: 70px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 250px;
    }
    .container-map.maximized {
        padding-bottom: 130px;
    }

    .container-search-config {
        width: 100%;
        bottom: 0px;
        top: auto;
        left: 0px;
        height: 250px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        transition: height .2s;
    }

    .container-search-config.minimized {
        width: 100%;
        height: 130px;
        transition: height .2s;
        padding: 20px;
        padding-top: 0px;
    }

    .container-search-config.minimized .content-minimized {
        display: block;
    }

    .container-search-config .collapse-bar {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .container-search-config.minimized .collapse-bar {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .container-search-config .collapse-bar .collapse-icon-smallscreen {
        display: block;
    }

    .container-search-config .collapse-bar .collapse-icon-bigscreen {
        display: none;
    }

    .container-search-config.minimized .collapse-bar .expand-icon-bigscreen {
        display: none;
    }

    .container-search-config.minimized .collapse-bar .expand-icon-smallscreen {
        display: inline;
    }
}