html, body, div#root {
    height: 100%;
}

.container-center {
    min-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    text-align: center;
    position: relative;
    padding-bottom: 50px;
    padding-top: 75px;
}

.container-center .container-center-inner {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.container-center .container-center-inner-wide {
    width: 100%;
    max-width: 600px;
    padding: 15px;
    margin: auto;
}

.container-center .container-center-inner .logo {
    width: 100%;
    margin-bottom: 50px;
}