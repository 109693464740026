.feather-lg {
    width: 24px;
    height: 24px;
}

.feather-btn {
    width: 16px;
    height: 16px;
    line-height: 1.0;
}

.button-link {
    margin: 0;
    padding: 0;
    border: 0;
    display: inline;
    vertical-align: baseline;
    line-height: 1;
}

.badge-top {
    vertical-align: top;
    margin-left: -3px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-10 {
    margin-top: 10px;
}

.icon-link {
    padding-top: 4px;
    padding-bottom: 4px;
}

.clickable-table tbody tr {
    cursor: pointer;
}

h2 {
    margin-top: 100px;
}

.padding-top {
    padding-top: 75px;
}

.center {
    text-align: center;
}

.btn:focus, .btn:active {
    background-color: #fff;
    box-shadow: none;
}