.container-signin {
    min-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    text-align: center;
    position: relative;
    padding-bottom: 50px;
    padding-top: 75px;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin .logo {
    width: 100%;
    margin-bottom: 50px;
}

.form-signin .form-control:focus {
    box-shadow: none;
}

.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin .form-check {
    margin-top: 10px;
    margin-bottom: 10px;
    width: auto;
}

.form-signin .form-check .form-check-input {
    float: none;
    margin-right: 0.25rem;
}

.btn-auth-provider {
    width: 100%;
}

.btn-back {
    margin-top: 25px;
}

.copyright-footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    font-size: 12px;
    width: 100%;
}